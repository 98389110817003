import { css } from "@emotion/react";

const defaultAnimationParameters = {
  transitionDuration: "200ms",
  transitionProperty: "all",
};

export const colors = {
  pastel: "#F5F1F6",
  strong: "#AB47BC",
};

export const animations = {
  hoverHighlight: {
    ":hover": {
      backgroundColor: "rgba(55, 53, 47, 0.08)",
    },
    transition: "background 120ms ease-in 0s",
  },
  hoverSlideRight: {
    ...defaultAnimationParameters,
    ":hover": {
      transform: "translateX(8px)",
    },
  },
};

export const typography = {
  sansSerif: {
    fontFamily: "Source Sans Pro, sans-serif",
  },
  serif: {
    fontFamily: "Merriweather, serif",
  },
};

export const md = css`
  ${typography.sansSerif}
  font-size: 1rem;
  text-align: justify;

  h1,
  h2,
  h3,
  h4 {
    ${typography.serif}
    text-align: left;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.2rem;
    font-weight: normal;
  }

  p {
    line-height: 1.2;
  }

  a {
    color: ${colors.strong};
    text-decoration: underline solid ${colors.strong};
  }
`;

const breakpoints = [576, 768, 992, 1200];

export const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
