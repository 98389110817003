const themes = {
  light: {
    elevation: (level) => ({
      boxShadow: `0px ${level}px ${level}px rgba(0,0,0, .24)`,
      shadowColor: "rgba(0,0,0, .24)",
      shadowOffset: { height: level, width: 0 },
      shadowOpacity: 1,
      shadowRadius: level,
    }),

    // ERROR
    error: "#DD2C00",
    errorDark: "#A30000",
    errorLight: "#FF6434",

    // PRIMARY
    primary: "#ab47bc",
    primary100: "#E1BEE7",
    primary200: "#CE93D8",
    primary300: "#BA68C8",
    primary400: "#AB47BC",
    primary50: "#F3E5F5",
    primary500: "#9C27B0",
    primary600: "#8E24AA",
    primary700: "#7B1FA2",
    primary800: "#6A1B9A",
    primary900: "#4A148C",
    primaryDark: "#790e8b",
    primaryLight: "#df78ef",

    // SECONDARY
    secondary: "#ce93d8",
    secondary100: "#E1BEE7",
    secondary200: "#CE93D8",
    secondary300: "#BA68C8",
    secondary400: "#AB47BC",
    secondary50: "#F3E5F5",
    secondary500: "#9C27B0",
    secondary600: "#8E24AA",
    secondary700: "#7B1FA2",
    secondary800: "#6A1B9A",
    secondary900: "#4A148C",
    secondaryDark: "#9c64a6",
    secondaryLight: "#ffc4ff",

    // SUCCESS
    success: "#64DD17",
    successDark: "#1FAA00",
    successLight: "#9CFF57",

    // SURFACE
    surface: "#fff",
    surfaceDark: "#f5f5f5",
    surfaceLight: "#fff",

    textBlackDisabled: "rgba(0, 0, 0, 0.4)",
    textBlackHigh: "rgba(0, 0, 0, 0.9)",
    textBlackMedium: "rgba(0, 0, 0, 0.6)",
    textBody: 15,
    textErrorDisabled: "rgba(255, 255, 255, 0.4)",
    textErrorHigh: "rgba(255, 255, 255, 1.0)",
    textErrorMedium: "rgba(255, 255, 255, 0.98)",

    textH1: 30,
    textH2: 25,
    textH3: 20,
    textH4: 18,
    textPrimaryDisabled: "rgba(255, 255, 255, 0.4)",
    textPrimaryHigh: "rgba(255, 255, 255, 1.0)",
    textPrimaryMedium: "rgba(255, 255, 255, 0.95)",
    textSecondaryDisabled: "rgba(0, 0, 0, 0.4)",
    textSecondaryHigh: "rgba(0, 0, 0, 0.9)",
    textSecondaryMedium: "rgba(0, 0, 0, 0.65)",
    textSmall: 12,
    textSurfaceDisabled: "rgba(0, 0, 0, 0.35)",
    textSurfaceHigh: "rgba(0, 0, 0, 0.9)",
    textSurfaceMedium: "rgba(0, 0, 0, 0.6)",
    textTiny: 10,
    textWarningDisabled: "rgba(0, 0, 0, 0.4)",
    textWarningHigh: "rgba(0, 0, 0, 0.9)",
    textWarningMedium: "rgba(0, 0, 0, 0.6)",
    textWhiteDisabled: "rgba(255, 255, 255, 0.4)",
    textWhiteHigh: "rgba(255, 255, 255, 1.0)",
    textWhiteMedium: "rgba(255, 255, 255, 0.6)",

    // WARNING
    warning: "#FFAB00",
    warningDark: "#C67C00",
    warningLight: "#FFDD4B",
  },
};

export default themes.light;
export { themes };
