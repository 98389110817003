/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";

import theme from "../themes";

import { typography, mq } from "./styles";
import { useTranslation } from "./translation";

const _strings = {
  en: {
    androidApp: "Android App",
    appName: "Gaston",
    appTagline: "Your virtual business secretary.",
    blog: "Blog",
    clientPortal: "Customer Portal",
    company: "Company",
    demo: "Request a Demo",
    followUs: "Follow Us",
    helpCenter: "Help Center",
    iosApp: "iOS App",
    legalEntity: "Gaston S.A.R.L.",
    press: "Press",
    pricing: "Pricing",
    privacy: "Privacy",
    product: "Product",
    ressources: "Ressources",
    support: "Support",
    team: "Our Mission",
    terms: "Terms & Conditions",
  },
  fr: {
    androidApp: "App Android",
    appName: "Gaston",
    appTagline: "Votre secrétaire virtuel intelligent.",
    blog: "Blog",
    clientPortal: "Espace client",
    company: "Compagnie",
    demo: "Demander une démo",
    followUs: "Suivez-nous",
    helpCenter: "Centre d'aide",
    iosApp: "App iOS",
    legalEntity: "Gaston S.A.R.L.",
    press: "Presse",
    pricing: "Tarifs",
    privacy: "Confidentialité",
    product: "Produit",
    ressources: "Ressources",
    support: "Support",
    team: "Notre Mission",
    terms: "Conditions générales",
  },
};

const H = styled.div({
  ...typography.serif,
  fontSize: "1.1rem",
  fontWeight: "bold",
  padding: "0.4rem 0.8rem",
});

const Sec = styled.div({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  padding: "0.8rem 1rem",
});

const El = styled(Link)({
  ...typography.sansSerif,
  ":hover": {
    backgroundColor: "rgba(55, 53, 47, 0.08)",
  },
  borderRadius: "0.4rem",
  color: theme.textSurfaceHigh,
  fontSize: "1rem",
  padding: "0.4rem 0.8rem",
  transition: "background 120ms ease-in 0s",
});

const A = styled.a({
  ...typography.sansSerif,
  ":hover": {
    backgroundColor: "rgba(55, 53, 47, 0.08)",
  },
  borderRadius: "0.4rem",
  color: theme.textSurfaceHigh,
  fontSize: "1rem",
  padding: "0.4rem 0.8rem",
  transition: "background 120ms ease-in 0s",
});

const Footer = ({ className }) => {
  const { strings } = useTranslation(_strings, "Footer");
  return (
    <footer
      css={{
        borderTop: "1px solid #E5E5E5",
        margin: "2rem 1rem 0",
        padding: "2rem 1rem",
        [mq[1]]: {
          display: "flex",
          justifyContent: "space-around",
          margin: "2rem 11vw 0",
          padding: "2rem 1vw",
        },
      }}
      className={className}
    >
      <Sec>
        <H css={{ paddingLeft: "0.4rem" }}>{strings.appName}</H>
        <div
          css={{
            ...typography.sansSerif,
            color: "#3F3D56",
            marginBottom: "0.8rem",
            marginLeft: "0.4rem",
          }}
        >
          {strings.appTagline}
        </div>
        <div
          css={{
            ...typography.sansSerif,
            color: "#3F3D56",
            fontStyle: "italic",
            marginBottom: "0.8rem",
            marginLeft: "0.4rem",
          }}
        >
          {strings.followUs}
        </div>
        <div
          css={{
            "& a": {
              color: "#3F3D56",
              marginRight: "0.4rem",
              padding: "0.4rem",
            },
            fontSize: "1.2rem",
            marginLeft: "0.4rem",
          }}
        >
          <A
            href="https://www.linkedin.com/company/GastonApps/"
            css={{
              ":hover": {
                color: "#0E76A8",
              },
            }}
          >
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </A>
          <A
            href="https://twitter.com/GastonApps"
            css={{
              ":hover": {
                color: "#00ACEE",
              },
            }}
          >
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </A>
          <A
            href="https://facebook.com/GastonApps"
            css={{
              ":hover": {
                color: "#1778F2",
              },
            }}
          >
            <FontAwesomeIcon icon={["fab", "facebook"]} />
          </A>
        </div>
      </Sec>
      <Sec>
        <H>{strings.company}</H>
        <El to="/team">{strings.team}</El>
        <A href="mailto:press@gaston.tel">{strings.press}</A>
      </Sec>
      <Sec>
        <H>{strings.product}</H>
        <El to="/pricing">{strings.pricing}</El>
        <El to="/demo">{strings.demo}</El>
        <A href="https://app.gaston.tel/">{strings.clientPortal}</A>
        <A href="https://itunes.apple.com/us/app/gaston/id1308164057?ls=1&mt=8">
          {strings.iosApp}
        </A>
        <A href="https://play.google.com/store/apps/details?id=tel.gaston.app">
          {strings.androidApp}
        </A>
      </Sec>
      <Sec>
        <H>{strings.ressources}</H>
        <A href="https://support.gaston.tel">{strings.helpCenter}</A>
        <A href="mailto:support@gaston.tel">{strings.support}</A>
        <El to="/terms">{strings.terms}</El>
        <El to="/privacy">{strings.privacy}</El>
      </Sec>
    </footer>
  );
};

export default Footer;
