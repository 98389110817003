/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";

import Footer from "./Footer";
import Header from "./Header";
import SiteMetadata from "./SiteMetadata";
import { TranslationProvider } from "./translation";

const Layout = ({ location, children }) => (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <SiteMetadata pathname={location.pathname} />
      <Header />
      <div
        css={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        {children}
      </div>
      <Footer />
    </div>
);

export default Layout;
