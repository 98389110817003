import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const SiteMetadata = ({ pathname }) => {
  const {
    site: {
      siteMetadata: {
        siteUrl,
        title,
        twitter,
        titleTemplate,
        description,
        keywords,
      },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          twitter
          titleTemplate
          description
          keywords
        }
      }
    }
  `);

  return (
    <Helmet defer={false} defaultTitle={title} titleTemplate={titleTemplate}>
      <html lang="fr" />
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <meta
        name="google-site-verification"
        content="9AQwCab4bB2uhYWaIoY573R34HA9oWqFGbQYZSdkyjE"
      />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="fr" />
      <meta property="og:site_name" content={title} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitter} />
    </Helmet>
  );
};

export default SiteMetadata;
